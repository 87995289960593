<template>
    <section class="component-slidergallery-vue relative z-20 xl:px-10 py-5 xl:py-10">
        <div class="container mx-auto">

                <splide v-show="blockData.gallery"  :options="options" ref="qwe">
                    <splide-slide v-for="img in blockData.gallery" :key="img.ID">
                        <img class="flex flex-col justify-center items-center mx-auto"  :src="img.url" alt="">
                    </splide-slide>
                </splide>

            <div class="controls flex gap-6 absolute w-full container justify-between top-1/2 left-1/2  -translate-x-1/2 -translate-y-1/2 z-10 px-5 xl:px-10  text-royal-blue">
                <div class="w-10 xl:w-[60px] h-10 xl:h-[60px] bg-white rounded-full grid place-content-center cursor-pointer" @click="prevSlide()">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M15 19l-7-7 7-7" />
                    </svg>
                </div>
                <div class="w-10 xl:w-[60px] h-10 xl:h-[60px] bg-white rounded-full grid place-content-center cursor-pointer" @click="nextSlide()">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M9 5l7 7-7 7" />
                    </svg>
                </div>
            </div>

        </div>
    </section>
</template>

<script>
    import { Splide, SplideSlide } from '@splidejs/vue-splide';
	import '@splidejs/splide/dist/css/themes/splide-default.min.css';
    export default {
        name: 'SliderGallery',
        components: {
			Splide,
			SplideSlide,
		},
        data() {
            return {
                options: {
                    type: 'loop',
                    focus: 'center',
                    rewind: false,
                    perPage: 1,
                    clones: 0,
                    perMove: 1,
                    arrows: false,
                    trimSpace: false,
                    gap: '1rem',
                    pagination: false,
                }, 
            }
        },
        mounted() {

        },
        methods: {
            handleSplideClick() {
                // console.log("clicked")
                // TODOimplement go to post page
            },
            nextSlide() {
                //this.$refs.triple.splide['Components'].Arrows.arrows.next.click()
                this.$refs.qwe.splide.go( '+' );
            },
            prevSlide() {
                //this.$refs.triple.splide['Components'].Arrows.arrows.prev.click()
                this.$refs.qwe.splide.go( '-' );
            }
        },
    }
</script>

<style>

.splide__list {
    will-change: auto!important;
}

</style>