<template>
    <section class="component-slidertriplecard-vue px-8 xl:px-10 py-20 relative z-20">

        <div class="bg-section-grey h-1/2 absolute top-0 left-0 right-0"></div>

        <div class="container mx-auto relative" v-if="data && templateData == 'Art'">
            <XyzTransition appear-visible duration="auto" >
                <div xyz="fade down duration-20 stagger">

                    <div class="flex justify-between pb-6 border-b border-hr mb-6 xl:mb-10" >
                        <h2 class="self-center font-serif text-[18px] xyz-nested" v-show="blockData.title">{{ blockData.title }}</h2>

                        <div class="controls hidden md:flex gap-6">
                            <div class="w-10 xl:w-[60px] h-10 xl:h-[60px] bg-white rounded-full grid place-content-center cursor-pointer border border-gray-300 xyz-nested" @click="prevSlide()">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M15 19l-7-7 7-7" />
                                </svg>
                            </div>
                            <div class="w-10 xl:w-[60px] h-10 xl:h-[60px] bg-white rounded-full grid place-content-center cursor-pointer border border-gray-300 xyz-nested" @click="nextSlide()">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M9 5l7 7-7 7" />
                                </svg>
                            </div>
                        </div>
                    </div>

                </div>
            </XyzTransition>

            <XyzTransition appear-visible duration="auto" >
                <div class="-mx-8 md:mx-0" >
                    <splide :options="options" @splide:click="handleSplideClick" :slides="data" ref="triple" >
                        <splide-slide v-for="(item, index) in data" :key="item.id" class="cursor-pointer">
                                <div class="mb-[14px] xl:mb-6 overflow-hidden">
                                    <img class="w-full xyz-nested transition-transform" v-show="item.data.fields.image" :src="item.data.fields.image.sizes.art" alt="item.data.fields.image.alt" xyz="big-5 delay-5 duration-30 out-back">
                                </div>
                                <div class="font-serif text-lg xl:text-2xl mb-1 leading-tight xyz-nested" xyz="fade down duration-10 stagger" v-show="item.title">{{ item.title.rendered }}</div>
                                <div class="text-sm opacity-40 xyz-nested" xyz="fade down duration-10 stagger" v-show="item.data.fields.location">{{ item.data.fields.location }}</div>
                                <div :data-index="index" hidden></div>
                        </splide-slide>
                    </splide>
                </div>
            </XyzTransition>
        </div>
    </section>
</template>

<script>
    import { Splide, SplideSlide } from '@splidejs/vue-splide';
	import '@splidejs/splide/dist/css/themes/splide-default.min.css';
    export default {
        name: 'SliderTripleCard',
        components: {
			Splide,
			SplideSlide,
		},
        data() {
            return {
                data: false,
                clickedIndex: null,
                options: {
                    type: 'loop',
                    focus: 'left',
                    rewind: false,
                    perPage: 3,
                    clones: 0,
                    perMove: 1,
                    arrows: false, // Ako ti trebaju custom komande ovo ne stavljaj na false, nego njegove sakri inace custom nece raditi
                    gap: '1rem',
                    pagination: false,
                    breakpoints: {
                        480: {
                            perPage: 1,
                            gap: '2rem',
                            padding: {
                                left: '30px',
                                right: '100px',
                            },
                        },
                        800: {
                            perPage: 2,
                        }
                    }
                }, 
            }
        },
        mounted() {
            this.$http.get( this.endpoints.rest_base_endpoint  + 'wp/v2/' + 'art')
            .then( res => { 
                    this.data = res.data;  
            });
        },
        methods: {
            handleSplideClick(splide, Slide) {
                // console.log(Slide)
                let index = parseInt(Slide.slide.childNodes[3].dataset.index)
                window.location.href = this.data[index].link
            },
            setClickedIndex(index) {
                this.clickedIndex = index
                // console.log(index)
            },
            nextSlide() {
                //this.$refs.triple.splide['Components'].Arrows.arrows.next.click()
                this.$refs.triple.splide.go( '+' );
            },
            prevSlide() {
                //this.$refs.triple.splide['Components'].Arrows.arrows.prev.click()
                this.$refs.triple.splide.go( '-' );
            }
        },
    }
</script>
