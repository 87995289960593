<template>
    <div class="component-flipcard-vue scene scene--card xyz-nested" >
        <div class="card overflow-hidden"
            v-bind:style="{ 'max-height' : cardImage.height + 'px' }"
                v-bind:class="{ flipme: cardOne == 'flipped' }"
            >

            <!-- front -->
            <div class="relative card__face card__face--front ">
                <div class="bg-black bg-opacity-[37%] absolute top-0 bottom-0 left-0 right-0"></div>
                <div class="absolute left-10 right-10 bottom-10 border-b border-white text-center pb-[28px]">
                    <h3 class="font-serif text-white text-lg tracking-[1px] uppercase" v-show="cardTitle" v-html="cardTitle"></h3>
                </div>
                <img class="object-cover h-full w-full" v-show="cardImage" :src="cardImage.url" :alt="cardImage.alt" >
            </div>
            <!-- ./front -->

            <!-- back -->
            <div class="card__face card__face--back bg-white shadow-card flex flex-col justify-between px-[12%] pb-[5%]">
                <img  class="mx-auto mt-[12%]" :src="endpoints.template_url + '/assets/images/kadena-k-blue.svg'">
                <div class=" border-b border-white text-center">
                    <h3 class="font-serif tracking-[1px] text-royal-blue text-lg uppercase mb-4" v-show="cardTitle" v-html="cardTitle"></h3>
                    <p class="text-royal-blue text-xs font-sans" v-show="cardText" v-html="cardText"></p>
                </div>

            </div>
            <!-- back -->
            <!-- <img width="100%" height="100%" class="invisible object-cover" v-show="cardImage" :src="cardImage.url" :alt="cardImage.alt" > -->
        </div>
    </div>
</template>

<script>
    export default {
        name: 'FlipCard',
        props: [
            'cardData',
        ],
        data() {
            return {
                cardOne: "start",
                isMobile: false
            }
        },
        mounted() {
            let mediaQuery = window.matchMedia('(min-width: 1024px)')
            if ( !mediaQuery.matches ) {
                this.isMobile = true
            } 
        },
        computed: {
            cardText: function () {  
                let text = false
                //  SquareList
                if ( this.templateData == 'Services' ) {
                    text =  this.$props.cardData.data.fields.text
                }
                //  TwoBoxes && TripleCard
                else {
                    text = this.$props.cardData.text
                }
                return text
                
            },
            cardTitle: function () { 
                let title = false 
                //  SquareList
                if ( this.templateData == 'Services' ) {
                    title =  this.$props.cardData.title.rendered
                }
                //  TwoBoxes && TripleCard
                else {
                    title = this.$props.cardData.title
                }
                return title
            },
            cardImage: function () {  
                let image = false
                //  SquareList
                if ( this.templateData == 'Services' ) {
                    image =  this.$props.cardData.data.fields.image
                }
                //  TwoBoxes && TripleCard
                else {
                    image = this.$props.cardData.image
                }
                return image
            },
        },
        created() {},
        methods: {
            handleClick() {
                this.cardOne == 'start' ? this.cardOne = 'flipped'  : this.cardOne = 'start'
            },
            mouseOver() {
                this.cardOne == 'start' && !this.isMobile ? (this.cardOne = 'flipped' ) : (this.cardOne = 'start' )
            },
            mouseOut() {
                this.cardOne == 'flipped' && !this.isMobile ? (this.cardOne = 'start' ) : (this.cardOne = 'flipped' )
            }
        }
    }
</script>