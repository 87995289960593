import Vue from 'vue'

//Vue.config.productionTip = false

import VueAxios from 'vue-axios'
import axios from './plugins/axios'

Vue.use(VueAxios, axios)

import { i18n } from "./plugins/i18n";

Vue.use(i18n)


import VueAnimXYZ from '@animxyz/vue'
import '@animxyz/core'

Vue.use(VueAnimXYZ)

import infiniteScroll from 'vue-infinite-scroll'

Vue.use(infiniteScroll)

import Mmenu from 'mmenu-js'
import 'mmenu-js/dist/mmenu.css'

import VueKinesis from 'vue-kinesis'

Vue.use(VueKinesis)


/* Detect Mobilee */
/* import VueMobileDetection from 'vue-mobile-detection'
Vue.use(VueMobileDetection)
 */
import { isMobile as detectMobile } from 'mobile-device-detect';

/* import FsLightbox from 'fslightbox-vue'
Vue.component( 'light-box', FsLightbox )  */

// Components
import Hero from './components/Hero'
import HeroVideo from './components/HeroVideo'
import ImageWithText from './components/ImageWithText'
import ImageWithCard from './components/ImageWithCard'
import ImageWithTextSteps from './components/ImageWithTextSteps'
import ImageWithTextCta from './components/ImageWithTextCta'
import Slider from './components/Slider'
import SliderGallery from './components/SliderGallery'
import SliderTripleCard from './components/SliderTripleCard'
import SquareList from './components/SquareList'
import TwoBoxes from './components/TwoBoxes'
import TripleBox from './components/TripleBox'
import ContactGroup from './components/ContactGroup'
import MediaPosts from './components/MediaPosts'
import MediaHero from './components/MediaHero'
import Form from './components/Form'
import QuickContactMenu from './components/QuickContactMenu'
import CustomSpacer from './components/CustomSpacer'
import ProgressRing from './components/ProgressRing'
import PortfolioItems from './components/PortfolioItems'


Vue.component('hero', Hero)
Vue.component('hero-video', HeroVideo)
Vue.component('image-with-text', ImageWithText)
Vue.component('image-with-card', ImageWithCard)
Vue.component('image-with-text-steps', ImageWithTextSteps)
Vue.component('image-with-text-cta', ImageWithTextCta)
Vue.component('slider', Slider)
Vue.component('slider-gallery', SliderGallery)
Vue.component('slider-triple-card', SliderTripleCard)
Vue.component('square-list', SquareList)
Vue.component('two-boxes', TwoBoxes)
Vue.component('triple-box', TripleBox)
Vue.component('contact-group', ContactGroup)
Vue.component('media-posts', MediaPosts)
Vue.component('media-hero', MediaHero)
Vue.component('interest-form', Form)
Vue.component('kart-form', Form)
Vue.component('kproptech-form', Form)
Vue.component('newsletter-form', Form)
Vue.component('quick-contact-menu', QuickContactMenu)
Vue.component('custom-spacer', CustomSpacer)
Vue.component('progress-ring', ProgressRing)
Vue.component('portfolio-items', PortfolioItems)


/* Vue.config.devtools = true */

//mixin used to implement methods needed to parse data passed from acf
Vue.mixin({
	props: {
		block_id: String
	},
	data() {
		return {
			blockData: false,
			fieldsData: false,
			optionsData: false,
			templateData: false,
			endpoints: false,
			archiveData: false,
			postData: false,
			showInterestForm: false,
			isMobile: false,
			currentLanguage: false
		}
	},
	created() {
		// this.detectMobile()
		//mount gutenberg block data if present
		if (typeof (window.blocks_data) !== 'undefined') {
			this.blockData = window.blocks_data[this.$props.block_id]
		}
		//mount acf fields data if present
		if (typeof (window.fields_data) !== 'undefined') {
			this.fieldsData = window.fields_data
		}
		//mount options acf fields data if present
		if (typeof (window.options_data) !== 'undefined') {
			this.optionsData = window.options_data
		}
		//mount template data if present
		if (typeof (window.page_template) !== 'undefined') {
			this.templateData = window.page_template
		}
		//mount endpoints data if present
		if (typeof (window.endpoints) !== 'undefined') {
			this.endpoints = window.endpoints
		}
		//mount archivetaxonomy data if present
		if (typeof (window.archive_taxonomy) !== 'undefined') {
			this.archiveData = window.archive_taxonomy
		}
		//mount archivetaxonomy data if present
		if (typeof (window.post_data) !== 'undefined') {
			this.postData = window.post_data
		}

		this.isMobile = detectMobile

		if (typeof (window.current_language) !== 'undefined') {
			this.currentLanguage = window.current_language
		}

	},
	methods: {
		__(term) {
			// console.log(term)
			let translation = this.$i18n.t(term)
			if (typeof (translation) === 'undefined' || translation == '') {
				return term
			}
			return translation
		},
	}
})

new Vue({
	i18n,
	el: '#wrapper',
	data() {
		return {
			mobileMenu: false,
			registerInterest: false,
			showPreloader: true,
			whiteLogo: true,
			isMobile: false,
			hideOnSubmit: false
		}
	},
	components: {
		/*  QuickContactMenu */
	},
	created() {
		window.addEventListener('scroll', this.handleScroll)
		this.isMobile = detectMobile
	},
	mounted() {
		console.log('vue mounted')

		// Preloader
		window.addEventListener('load', (event) => {
			this.preloaderLoaded()
		})

		this.$i18n.setLocaleMessage(window.current_language, window.translations_data)
		this.$i18n.locale = window.current_language

		window.showHideForm = this.showHideForm

		setTimeout(() => {
			this.$refs.interestForm.style.opacity = 1
			if (location.hash) {
				document.getElementById(location.hash.substring(1)).scrollIntoView()
			}
		}, 1500);


		if (this.isMobile) {
			var self = this;

			Mmenu.configs.offCanvas.page.selector = "#wrapper";
			document.addEventListener(
				"DOMContentLoaded", () => {
					self.mobileMenu = new Mmenu("#mobileMenu", {
						// options
						"slidingSubmenus": false,

						"navbars": [
							/*
							{
								"position": "bottom",
								"content": [
									'<button id="mob-register" type="button" class="mob-register-int text-white px-3 py-4 border-2 border-white bg-royal-blue active:bg-opacity-80  transition-all cursor-pointer" onclick="showHideForm()">REGISTER INTEREST</button>'
								]
							}
								*/
						],
						"extensions": [
							"position-right",
							"pagedim-black",
						],
					},);
				}
			)
		}

		/* Succesfully sent Interest form */
		let wpcf7Elm = document.querySelector('#interest-form .wpcf7');
		let hideFields = document.querySelectorAll('#interest-form .hide-on-submit, #interest-form .wpcf7 .form-group, #interest-form .wpcf7 ._submit, #interest-form .wpcf7 ._accept')
		let outputMsg = document.querySelector('.wpcf7-response-output')

		wpcf7Elm.addEventListener('wpcf7mailsent', function (event) {
			outputMsg.style.display = 'block';
		})
		// console.log(wpcf7Elm);
		wpcf7Elm.addEventListener('wpcf7mailsent', function (event) {
			// ga('send','event','eventCategory','eventAction','eventLabel');
			hideFields.forEach(function (e) {
				e.classList.add('hide-me')
			})
			this.hideOnSubmit = true
		}, false);

		this.phoneTextFieldPreventCountryDelete()

	},
	destroyed() {
		window.removeEventListener('scroll', this.handleSCroll)
	},
	methods: {
		phoneTextFieldPreventCountryDelete() {
			//since there is no way to know when phone field intl-tel-country is initialized we have to use timeout
			setTimeout(() => {
				this.$el.querySelectorAll('.intl-tel-input').forEach((el) => {
					var countryCodeEl = el.parentElement.querySelectorAll('.wpcf7-phonetext-country-code')[0]
					var initialCountryCode = countryCodeEl.value
					el.currentCountryCode = initialCountryCode
					el.querySelectorAll('.country-list .country').forEach((cel) => {
						cel.addEventListener('click', (e) => {
							el.currentCountryCode = '+' + e.currentTarget.dataset.dialCode
						})
					})
					var phoneInput = el.querySelectorAll('.wpcf7-phonetext')[0]
					phoneInput.addEventListener('keyup', (e) => {
						if (e.target.value.length <= el.currentCountryCode.length) {
							e.target.value = el.currentCountryCode
						}
					})
				})
			}, 1000)
		},

		handleScroll(event) {
			let header = document.querySelector("#header");
			if (window.scrollY > 50 && !header.className.includes('header-change')) {
				header.classList.add('header-change');
				this.whiteLogo = false
			} else if (window.scrollY < 50) {
				header.classList.remove('header-change');
				this.whiteLogo = true
			}
		},
		preloaderLoaded() {
			this.showPreloader = false;
			document.body.classList.remove('preloader-site');

		},
		showHideForm() {
			let wpcf7Elm = document.querySelector('#interest-form .wpcf7');
			let hideFields = document.querySelectorAll('#interest-form .hide-on-submit, #interest-form .wpcf7 .form-group, #interest-form .wpcf7 ._submit, #interest-form .wpcf7 ._accept')

			if (this.$refs.interestForm.classList.contains('invisible') && !this.showInterestForm) {
				this.$refs.interestForm.style.visibility = 'visible'
				this.showInterestForm = true

			} else {

				setTimeout(() => {
					this.$refs.interestForm.style.visibility = 'hidden'

					// show form again and reset style
					hideFields.forEach(function (e) {
						if (e.classList.contains('hide-me')) {
							e.classList.remove('hide-me')
							wpcf7Elm.setAttribute('data-status', 'init');
							wpcf7Elm.classList.remove('sent');
							wpcf7Elm.classList.remove('invalid');
							wpcf7Elm.classList.add('init');
							wpcf7Elm.querySelector('.wpcf7-response-output').innerHTML = '';
							wpcf7Elm.querySelector('.wpcf7-response-output').style.display = 'none'
							wpcf7Elm.querySelectorAll('.wpcf7-not-valid-tip').forEach(function (elem) {
								elem.innerHTML = '';
							});
						}
					})
					this.hideOnSubmit = false

				}, 500);
				this.showInterestForm = false

			}
			this.closeMenu()

		},
		openMenu() {
			const api = this.mobileMenu.API
			const panel = document.querySelector("#mobileMenu");
			api.openPanel(panel)
		},
		closeMenu() {
			const api = this.mobileMenu.API
			const panel = document.querySelector(".menu-wrapper");
			api.close(panel)
		},
	}
});
