<template>
    <section class="component-imagewithvideo-vue relative z-20 overflow-hidden" :class="blockData.blockClasses">


        <!-- lines -->
        <svg id="k-lines" data-name="Layer 2" class="absolute  -translate-x-1/2 w-auto h-[80%] xl:h-full bottom-0 xl:top-0 fill-current  text-white z-10" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2145.94 1080"
            :class="{'left-[10%] md:left-1/2' : blockData.block_orientation === 'text-right', 'left-[10%]' : blockData.block_orientation === 'text-left'  }"
        >
                <g id="ui" stroke-width="2" fill="none" fill-rule="evenodd" stroke="#FFFFFF" >
                    <line class="cls-1" x1="1473.93" y1="648" x2="2145.94" data-start="10" data-duration="20"/>
                    <line class="cls-1" x1="1994.93" y1="1080" x2="1473.93" y2="648" data-start="20" data-duration="20"/>
                    <line class="cls-1" x1="1473.93" x2="1473.93" y2="648" data-start="30" data-duration="45"/>
                    <line class="cls-1" x1="1474.44" y1="647.5" x2="1033.97" y2="1080" data-start="45" data-duration="30"/>
                    <line class="cls-1" x1="1073.3" y1="1041.39" x2="1073.3" data-start="45" data-duration="30"/>
                    <line class="cls-1" x1="1072.97" y1="1040.87" x2="1116.82" y2="1080"/>
                </g>
        </svg>
        <!-- /lines -->
        <div class="container px-8 xl:px-0 relative z-20 mx-auto flex flex-col gap-20 items-center py-[40px]"
            :class="{ 'md:flex-row' : blockData.block_orientation === 'text-right', 'md:flex-row-reverse' : blockData.block_orientation === 'text-left', }" >
            <div class="xl:w-5/12 overflow-hidden">
                <XyzTransition appear-visible duration="auto" xyz="big-5 delay-5 duration-30 out-back">
                    <img class="image-cut-off w-full" v-show="blockData.image" :src="blockData.image.url" :alt="blockData.alt" >
                </XyzTransition>
            </div>
            
            <XyzTransition :appear-visible="{ threshold: 0.3, rootMargin: '20px' }" duration="auto" >
                <div class="xl:w-5/12 xl:mx-auto" xyz="fade down duration-10 stagger">
                    <div class="font-serif text-sm mb-[26px]" v-show="blockData.kicker" v-html="blockData.kicker" ></div>
                    <h3 class="font-serif tracking-[1px] text-[32px] leading-tight mb-4" v-show="blockData.title" v-html="blockData.title" ></h3>
                    <div class="font-sans leading-normal text-sm mb-[40px]" v-show="blockData.text" v-html="blockData.text" ></div>
                    <template v-if="blockData.cta_type != 'Link'">
                        <button class="px-[42px] btn btn--transparent-blue "  @click="toggler = !toggler" v-show="blockData.video_button_text && blockData.video">{{ blockData.video_button_text }}</button>
                    </template>
                    <template v-else>
                        <a v-if="blockData.cta" :href="blockData.cta.url" :target="blockData.cta.target">{{ blockData.cta.title }}</a>
                    </template>
                </div>
            </XyzTransition>
        </div>
        
        <FsLightbox
            v-if="!isMobile"
            :toggler="toggler"
            :sources="[{
                component: CustomSource,
                props: { video: blockData.video }
            }]"
        />
        <FsLightbox
            v-if="isMobile"
            :toggler="toggler"
            :sources="[
                'https://www.youtube.com/embed/' + blockData.video,
            ]"
        />
    </section>
</template>

<script>
    import FsLightbox from 'fslightbox-vue'
    import CustomSource from "./CustomSource.vue"
    export default {
        name: 'ImageWithTextCta',
        components: { FsLightbox },
        data() {
            return {
                toggler: false,
                CustomSource: CustomSource,
                isMobile: false
            }
        },
        mounted() {
            let mediaQuery = window.matchMedia('(min-width: 1024px)')
            if ( !mediaQuery.matches ) {
                this.isMobile = true
            } 
        },
        methods: {

        },
    }
</script>

<style scoped>

.image-cut-off {
    clip-path: polygon(0% 0%, 100% 0, 100% 95%, 93% 100%, 0% 100%);
}

</style>