<script>
    export default {
        name: 'Form',
        data: function() {
            return {

            }
        },
        mounted() {
           this.testing()
        },
        methods : {
            testing() {
                let attachInput = document.getElementsByClassName('your-file')
                
                if(attachInput.length > 0) {
                    
                    let uploadFile = document.getElementsByClassName('your-file')[0].getElementsByTagName('INPUT')[0]
                    let fileName = document.getElementsByClassName('_file')[0].childNodes[1]
                    let note = document.getElementsByClassName('_file')[0].childNodes[2]
                    let removeBtn = document.getElementsByClassName('remove-pdf-file')[0]
    
                    removeBtn.style.display = 'none'
    
                    uploadFile.addEventListener('change', (event) => {
                        if ( event.target.files.length !== 0 ) {
                            // console.log(event.target.files)
                            fileName.innerHTML = event.target.files[0].name 
                            removeBtn.style.display = 'block'
                            note.style.display = 'none'
                        }
                    }); 
    
                    removeBtn.addEventListener('click', (event) => {
                        uploadFile.value = null
                        fileName.innerHTML = 'Attach file'
                        removeBtn.style.display = 'none'
                        note.style.display = 'block'
                    })
                }

            }
        },
    }
</script>