<template>
    <div ref="slide" v-if="slideData" class="flex relative">
        <div ref="image" class="slide-card-front z-20 overflow-hidden">
             <XyzTransition :appear-visible="{ threshold: 0.5, rootMargin: '50px' }" duration="auto" xyz="big-5 delay-5 duration-30 out-back">
                <img class="object-cover w-full h-full" v-show="slideData.image" :src="slideData.image.url" :alt="slideData.image.alt">
             </XyzTransition>
        </div>
        <div ref="content" class="slide-card-back overflow-hidden flex flex-col z-10 bg-white py-11 pl-8 pr-12">
            <div class="text-lg font-serif uppercase leading-tight mb-auto tracking-[1px]" v-show="slideData.title" v-html="slideData.title"></div>
            <div class="text-xs leading-normal" v-show="slideData.text" v-html="slideData.text"></div>
        </div>
    </div>
</template>

<script>
    import anime from 'animejs/lib/anime.es'

    export default {
        name: 'SingleSlide',
        data() {
            return {
                slideData: false,
                width: 0,
                gap: 0,
                rightPoint: 0,
                animDuration: 1000,
                easingFunction: 'easeOutCubic'
            }
        },
        mounted() {
        },
        methods: {
            setSlideData( data ) {
                this.slideData = data
            },
            setSize( width, gap ) {
                this.width = width
                this.gap = gap
                this.rightPoint = ( this.width / 2 )
                this.$nextTick( function() {
                    this.$refs.slide.style.width = width + 'px'
                    this.$refs.slide.style.marginRight = gap + 'px'
                    this.$refs.content.style.width = ( width / 2 ) + 'px'
                    this.$refs.image.style.width = ( width /2 ) + 'px'
                } )
            },
            setAlignment( value ) {
                this.$nextTick( function() {
                    // console.log('setting')
                    switch ( value ) {
                        case 'left':
                            this.$refs.content.style.transform = 'translateX(-' + ( this.width / 2 ) + 'px)'
                            this.$refs.image.style.transform = 'translateX(0px)'
                            break;
                        case 'right':
                            this.$refs.content.style.transform = 'translateX(0px)'
                            this.$refs.image.style.transform = 'translateX(' + this.rightPoint + 'px)'
                            break;
                    }
                } )
            },
            openInstant() {
                this.$nextTick( function() {
                    this.$refs.content.style.transform = 'translateX(0px)'
                } )
            },
            openLeft() {
                anime({
                    targets: this.$refs.content,
                    translateX: 0,
                    translateZ: 0,
                    easing: this.easingFunction,
                    duration: this.animDuration
                })
            },
            openRight() {
                anime({
                    targets: this.$refs.image,
                    translateX: 0,
                    translateZ: 0,
                    easing: this.easingFunction,
                    duration: this.animDuration
                })
            },
            closeLeft() {
                anime({
                    targets: this.$refs.content,
                    translateX: - ( this.width / 2 ),
                    translateZ: 0,
                    easing: this.easingFunction,
                    duration: this.animDuration
                })
            },
            closeRight() {
                anime({
                    targets: this.$refs.image,
                    translateX: ( this.width / 2 ),
                    translateZ: 0,
                    easing: this.easingFunction,
                    duration: this.animDuration
                })
            }
        }
    }
</script>