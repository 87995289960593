<template>
    <section :class="[blockData.spacer_background_color === 'white' ? 'bg-white' : 'bg-section-grey']" class="component-customspacer-vue relative z-20" :style="{ 'height' : blockData.spacer_space + 'px' }">
    </section>
</template>

<script>
    export default {
        name: 'CustomSpacer',
        data() {
            return {}
        },
        mounted() {},
        methods: {}
    }
</script>

<style scoped>
.component-customspacer-vue {
    opacity: 0.9999;
}
</style>