<template>
    <svg
    :height="$props.radius * 2"
    :width="$props.radius * 2"
  >
    <circle
      stroke="#1A2C69"
      fill="transparent"
      :stroke-dasharray="circumference + ' ' + circumference"
      :style="{ strokeDashoffset }"
      :stroke-width="$props.stroke"
      :r="normalizedRadius"
      :cx="$props.radius"
      :cy="$props.radius"
    />
  </svg>
</template>

<script>
    export default {
        name: 'ProgressRing',
        props: [
            'radius',
            'progress',
            'stroke'
        ],
        data() {
            return {
                normalizedRadius: false,
                circumference: false
            }
        },
        mounted() {
            this.normalizedRadius = this.$props.radius - this.$props.stroke * 2;
            this.circumference = this.normalizedRadius * 2 * Math.PI;
        },
        computed: {
            strokeDashoffset() {
                return this.circumference - this.calcProgress / 100 * this.circumference
            },
            calcProgress() {
                return eval(this.$props.progress)
            }
        },    
        methods: {

        }
    }
</script>

<style scoped>

circle {
          transition: stroke-dashoffset 0.35s;
          transform: rotate(-90deg);
          transform-origin: 50% 50%;
        }

</style>