<template>
    <div class="component-mediahero-vue relative text-white h-[50vh] flex py-12 mb-10 overflow-hidden">
        <div class="container mx-auto mt-auto px-4 xl:px-0 relative z-[11]">
            <template v-if="postData.post_name == 'data-protection-policy' ||  postData.post_name == 'cookies-policy'">
                <div class="text-sm font-serif mb-4 leading-none ">KADENA PROPERTIES</div>
                <h1 class="font-serif text-[32px] leading-[1.125] uppercase" v-html="postData.post_title"></h1>
            </template>
            <template v-else>
                <div class="text-sm font-serif mb-4 leading-none " v-if="archiveData.category_name === 'news'" v-html="'NEWS'"></div>
                <div class="text-sm font-serif mb-4 leading-none " v-if="archiveData.category_name === 'careers'" v-html="'CAREERS'"></div>
                <div class="text-sm font-serif mb-4 leading-none " v-if="archiveData.category_name === 'blog'" v-html="'BLOG'"></div>
                <h1 class="font-serif text-[32px] leading-[1.125]" v-if="archiveData.category_name === 'news'" v-html="'LATEST FROM KADENA'"></h1>
                <h1 class="font-serif text-[32px] leading-[1.125]" v-if="archiveData.category_name === 'careers'" v-html="'OPEN POSITIONS'"></h1>
                <h1 class="font-serif text-[32px] leading-[1.125]" v-if="archiveData.category_name === 'blog'" v-html="'FROM OUR LIBRARY'"></h1>
            </template>
        </div>
    </div>
</template>


<script>
    export default {
        name: 'MediaHero',
        data() {
            return {

            }
        },
        mounted() {

        },
        methods: {

        },
    }
</script>