<template>

    <section class="component-twoboxes-vue px-8 xl:px-10 xl:my-20 relative z-20">
        <XyzTransition appear-visible duration="auto">
        <div xyz="fade down duration-15 stagger">
            <div class="container mx-auto py-10">
                <h2 class="font-serif text-[18px] tracking-[1px] xyz-nested" v-show="blockData.title" v-html="blockData.title"></h2>
                <hr class=" mt-[47px] mb-[32px] border-hr xyz-nested">
                <div class="grid grid-cols-none sm:grid-cols-2 gap-5 xl:gap-10 xyz-nested" >
                    <FlipCard v-for="( box, index ) in data" :key="index" :cardData="box"></FlipCard>
                </div>
            </div>
        </div>
        </XyzTransition>
    </section>
</template>


<script>
    import FlipCard from './FlipCard.vue'

    export default {
        name: 'TwoBoxes',
        data() {
            return {
     /*            cardOne: "start", */
                data: false
            }
        },
        mounted() {
            this.data = [ this.blockData.left_box, this.blockData.right_box ]
        },
        methods: {

        },
        components: {
            FlipCard
        }
    }
</script>


