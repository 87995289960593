<template>
    <section class="component-squarelist-vue relative z-20 px-8 md:px-10 py-10 md:py-20" :class="blockData.blockClasses">
        <XyzTransitionGroup :appear-visible="{ threshold: 0.5, rootMargin: '50px' }" xyz="fade down duration-20 ease" class="container mx-auto grid grid-cols-1 md:grid-cols-3 gap-4">
            <div v-show="data && templateData == 'Services'" v-for="item in data" :key="item.id">
                <FlipCard :id="item.slug" :cardData="item"></FlipCard>
            </div>
        </XyzTransitionGroup>
    </section>
</template>

<script>
    import FlipCard from './FlipCard.vue'

    export default {
        name: 'SquareList',
        data() {
            return {
                data: false
            }
        },
        mounted() {
            this.$http.get( this.endpoints.rest_base_endpoint  + 'wp/v2/' + 'service')
            .then( res => { 
                    this.data = res.data;  
                }
            );
        },
        methods: {

        },
        components: {
            FlipCard
        }
    }
</script>