<template>
    <section
        class="component-portfolioitems-vue grid grid-cols- gap-4 relative min-h-[500px] bg-section-grey px-0 xl:px-10 z-20" :class="blockData.blockClasses">
        <div
        :class="{ 'md:flex-row container px-8 xl:px-0 relative z-20 mx-auto flex flex-col gap-20 items-center py-[40px]' : blockData.block_orientation === 'text-left', 'md:flex-row-reverse container px-8 xl:px-0 relative z-20 mx-auto flex flex-col gap-20 items-center py-[40px]' : blockData.block_orientation === 'text-right' }">
            <XyzTransition :appear-visible="{ threshold: 0.5, rootMargin: '50px' }" duration="auto">
                <div class="md:w-5/12 md:pr-10" xyz="fade down duration-10 stagger">
                    <img class="logo" v-if="blockData.logo" :src="this.blockData.logo.sizes.large"
                        :alt="blockData.logo.alt">
                    <h4 class="text-2xl mb-4 font-serif tracking-[1px] " v-if="this.blockData.title"
                        v-html="blockData.title"></h4>
                    <div v-show="this.blockData.description" v-html="this.blockData.description"
                        class="text-[14px] leading-[22px] "></div>
                    <img class="services" v-if="blockData.services_image" :src="this.blockData.services_image.sizes.large"
                        :alt="blockData.logo.alt">
                </div>
            </XyzTransition>
        </div>
        <div
            :class="{ 'md:flex-row md:absolute md:top-0 md:left-1/2 md:right-0 md:bottom-0 object-cover object-center md:w-1/2 md:h-full overflow-hidden' : blockData.block_orientation === 'text-left', 'md:flex-row-reverse md:absolute md:top-0 md:bottom-0 object-cover object-center md:w-1/2 md:h-full overflow-hidden md:right-1/2 md:left-0' : blockData.block_orientation === 'text-right' }">
            <XyzTransition appear-visible duration="auto" xyz="big-5 delay-5 duration-30 ease-ease">
                <img class="w-full main-image" v-if="blockData.image" :src="this.blockData.image.sizes.large"
                    :alt="blockData.image.alt">
            </XyzTransition>
        </div>
    </section>
</template>






<script>

export default {
    name: 'PortfolioItems',
    data() {
        return {
        }
    },
    mounted() {
        // console.log(this.blockData)
    },
    methods: {

    }
}
</script>

<style scoped>


.component-portfolioitems-vue .logo {
    width: 150px;
    height: auto;
    object-fit: contain;
    margin-bottom: 50px;
}

.component-portfolioitems-vue .services {
    width: auto;
    height: 70px;
    object-fit: contain;
    margin-top: 15px;
}

.component-portfolioitems-vue .main-image {
    object-fit: cover;
    height: 100%;
    width: 100%;
    display: block;
}

</style>