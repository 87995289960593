<template>
    <section class="component-mediapost-vue ">
        <div v-infinite-scroll="loadMore" infinite-scroll-disabled="busy" infinite-scroll-distance="limit">
           <XyzTransitionGroup :appear-visible="{ threshold: 0.5, rootMargin: '50px' }" xyz="fade down duration-10 ease">


            <article class="relative grid xl:mb-11 xl:px-10 bg-section-grey" v-show="posts" v-for="item in posts" :key="item.id">
                <div class="md:absolute md:top-0 md:left-1/2 md:right-0 md:bottom-0 object-cover object-center md:w-1/2 md:h-full overflow-hidden article-image">
                    <img class="w-full xyz-nested" v-show="item.data.fields.image" :src="item.data.fields.image.url" :alt="item.data.fields.image.alt" xyz="big-5 delay-5 duration-30 out-back">
                </div>

                <div class="container w-full mx-auto self-center px-8 xl:px-0 py-14 md:py-0  md:my-[190px]">
                    <div class="article-content md:w-5/12 md:pr-10">
                        <div class="mb-4 font-serif " v-show="item.date" >{{ formatDate(item.date) }}</div> 
                        <h4 class="font-serif tracking-[1px] text-2xl leading-tight mb-4" v-show="item.title.rendered" v-html="item.title.rendered"></h4>
                        <div class="text-sm mb-8" v-show="item.data.fields.card_text" v-html="item.data.fields.card_text"></div>
                        <button class="btn btn--transparent-blue w-[200px]" @click="handleClick(item)">READ MORE</button>
                    </div>
                </div>
                

            </article>


            </XyzTransitionGroup>
        </div>
    </section>
</template>

<script>
    import { format, parseISO } from 'date-fns'
    export default {
        name: 'MediaPosts',
        data() {
            return {
                posts: [],
                limit: 4,
                busy: false
            }
        },
        mounted() {

        },
        methods: {
            handleClick(item) {
                window.location.href = item.link;
            },
            formatDate(date) {
                let formated = format( parseISO(date), 'dd.MM.yyyy.')
                return formated
            },
            loadMore() {
                // console.log("Adding 10 more data results");
                this.busy = true;

                var url_params = {
                    'categories':   this.archiveData.category_id  
                }

                var searchParams = new URLSearchParams( url_params );

                this.$http.get( this.endpoints.rest_base_endpoint  + 'wp/v2/posts?' + searchParams)
                    .then( res => { 
                            const append = res.data.slice(
                            this.posts.length,
                            this.posts.length + this.limit
                            );
                            this.posts = this.posts.concat(append);
                            this.busy = false; 
                        }
                ); 
            }    
        },
        created() {
            this.loadMore()
        }
    }
</script>