<template>
    <section class="component-imagewithcard-vue relative z-20 bg-section-grey bg-cover" :style="{'background-image':'url('+blockData.big_image.url+')'}">

        <XyzTransition :appear-visible="{ threshold: 0.5, rootMargin: '50px' }" duration="auto">
        <div class="container px-8 xl:px-0 py-[52px] lg:py-[100px] mx-auto " xyz="fade left duration-15">
            <div class="bg-white bg-opacity-80 max-w-[384px] p-[40px] text-center">
                <img  class="mb-[100px] lg:mb-[210px] mx-auto" :src="endpoints.template_url + '/assets/images/kadena-k-blue.svg'">
                <div class="font-serif text-[14px] mb-[24px]" v-show="blockData.title" >{{blockData.title}}</div>
                <div class="font-sans text-center" v-show="blockData.text" v-html="blockData.text" ></div>
            </div>
        </div>
        </XyzTransition>
        <!-- <img class="absolute top-0 bottom-0 right-0 left-0 w-full h-full object-cover object-center" v-show="blockData.big_image" :src="blockData.big_image.url" :alt="blockData.big_image.alt" > -->
        <!-- <img v-show="blockData.small_image" :src="blockData.small_image.url" :alt="blockData.small_image.alt" > -->

    </section>
</template>

<script>
    export default {
        name: 'ImageWithCard',
        data() {
            return {

            }
        },
        mounted() {

        },
        methods: {

        },
    }
</script>