<template>
    <section class="component-herovideo-vue hero hero--home sticky top-0 h-screen w-full overflow-hidden flex items-center" >
        
        
        <!-- Img or video -->

            <!-- Img or video -->

            <div v-if="isMobile" class="hero_overlay absolute top-0 left-0 w-full h-full z-10 after:block after:w-full after:h-full after:bg-[rgba(0,0,0,0.2)] after:absolute after:top-0 after:right-0">
                <video autoplay muted playsinline loop class="w-full h-full object-cover">
                    <source :src="blockData.video.url" >
                </video>
            </div>



            <kinesis-container v-else event="scroll" :duration="100" tag="div" class="hero_overlay absolute top-0 left-0 w-full h-full z-10 after:block after:w-full after:h-full after:bg-[rgba(0,0,0,0.2)] after:absolute after:top-0 after:right-0">
                <kinesis-element :strength="700" style="position: relative; z-index: -1">
                    <video autoplay muted playsinline loop class="w-full h-full object-cover">
                        <source :src="blockData.video.url" >
                    </video>
                </kinesis-element>
            </kinesis-container>



        <!-- content in hero -->
            <div class="hero__content container mx-auto px-8 xl:px-0 py-20 z-20 text-center xl:text-left">
                    <div class="min-h-[36px]" >
                        <XyzTransition appear xyz="fade duration-15">
                            <h1 v-show="active" v-html="activeText1" class="text-white text-2xl leading-normal tracking-[1px] uppercase font-serif "></h1>
                        </XyzTransition>
                    </div>
                    <div class="min-h-[36px]" >
                        <XyzTransition appear xyz="fade duration-15 delay-15">
                            <h1 v-show="active" v-html="activeText2" class="text-white text-2xl leading-normal tracking-[1px] xl:pl-6 uppercase font-serif "></h1>
                        </XyzTransition>
                    </div>
            </div>


        <!-- ancor -->
        <a class="hero__ancor z-30" href="#section-1"></a>
        <!-- ./ancor -->


        <div id="trt" class="absolute bottom-0 left-0 w-full z-10 overflow-hidden">

            <svg id="k-lines" v-if="!isMobile" class="absolute w-auto h-screen bottom-0 left-1/2 -translate-x-1/2 z-10 fill-current  text-white" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                viewBox="0 0 1440 768" style="enable-background:new 0 0 1440 768;" xml:space="preserve">
                <g id="kadena-k-svg" >
                    <g id="Group" fill="none" fill-rule="evenodd" stroke="#FFFFFF">
                        <!-- <rect id="Rectangle" y="0" class="st0" width="1440" height="768"/> -->
                        <g id="Bitmap" transform="translate(719.000000, 79.000000)">
                            <g id="Group-2">
                                <line id="Path-2" class="st1" x1="0.5" y1="637.9" x2="722.9" y2="-67.4" data-start="10" data-duration="20"/>
                                <line id="Path-3" class="st1" x1="690" y1="691" x2="288.4" y2="355.5" data-start="20" data-duration="20"/>
                                <line id="Path-4" class="st2" x1="0.5" y1="637.9" x2="0.5" y2="-79" data-start="30" data-duration="45"/>
                                <line id="Path-4-Copy" class="st2" x1="288.9" y1="355.5" x2="288.9" y2="-79" data-start="45" data-duration="30"/>
                            </g>
                        </g>
                        <polygon id="Path-7" class="st3" points="663,768 783,768 721,714" fill="white"/>
                    </g>
                </g>
            </svg>            
  
            <!-- Moobile lines -->
            <svg id="k-lines" v-else class="mobile-lines absolute w-auto h-screen -bottom-1 left-1/2 -translate-x-1/2 z-10 fill-current  text-white" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                viewBox="0 0 2261.3 1920" style="enable-background:new 0 0 2261.3 1920;" xml:space="preserve">
                <line stroke="#FFFFFF" class="st0" x1="1305.3" y1="1944.7" x2="756.4" y2="1479.3" data-start="10" data-duration="20"/>
                <line stroke="#FFFFFF" class="st0" x1="756.4" y1="1479.3" x2="756.4" y2="-62.8" data-start="20" data-duration="20"/>
                <line stroke="#FFFFFF" class="st0" x1="1196.2" y1="1050.3" x2="1196.2" y2="-62.8" data-start="30" data-duration="45"/>
                <line stroke="#FFFFFF" class="st0" x1="2251.2" y1="1943.7" x2="1196.2" y2="1050.3" data-start="45" data-duration="30"/>
                <line stroke="#FFFFFF" class="st0" x1="239.1" y1="1983.8" x2="2337.6" y2="-62.8" data-start="40" data-duration="30"/>
                <polygon id="Path-7" class="st1" points="980.1,1919.6 1281.3,1919.6 1130.8,1792.9 " fill="white"/>
            </svg>

        </div>
        
    </section>
</template>

<script>
    import Vivus from 'vivus'

    export default {
        name: 'HeroVideo',
        data() {
            return {
                vivusOptions: {
                    duration: 200,
                    type: 'scenario'
                },
                active: false,
                activeText1: false,
                activeText2: false,
                textCounter: 0,
            }
        },
        mounted() {
            let animSvg = new Vivus('k-lines', this.vivusOptions, null);
            animSvg.play()

            this.activeText1 = this.blockData.animated_text[ this.textCounter ].text_1
            this.activeText2 = this.blockData.animated_text[ this.textCounter ].text_2

            this.switchText()




        },
        methods: {
            switchText() {
                setTimeout(() => {
                    this.active = !this.active
                    setTimeout(() => {
                        if ( !this.active ) {
                            this.textCounter += 1
                            if ( this.textCounter >= this.blockData.animated_text.length ) {
                                this.textCounter = 0
                            }
                            this.activeText1 = this.blockData.animated_text[ this.textCounter ].text_1
                            this.activeText2 = this.blockData.animated_text[ this.textCounter ].text_2
                        }
                        this.switchText()
                    }, 3000);
                }, 1500);
            }
        },
    }
</script>