<template>
    <section class="component-contactgroup-vue py-10 md:py-20 bg-section-grey relative z-20">
        <XyzTransition :appear-visible="{ threshold: 0.5, rootMargin: '50px' }" duration="auto" xyz="fade down duration-15 stagger">
        <div class="container mx-auto grid grid-flow-row md:grid-flow-col gap-12 md:gap-20  px-8 xl:px-0">
            <div v-show="blockData.contact_group" v-for="( item, index ) in blockData.contact_group" :key="index">
                <h3 class="font-serif text-[14px] uppercase" v-show="item.title" v-html="item.title"></h3>
                <hr class="border-b border-hr my-6">
                <p class="font-sans text-[14px] leading-[22px]" v-show="item.text" v-html="item.text"></p>
                <a class="font-sans text-[12px] mt-6" v-show="item.email" v-html="item.email" :href="'mailto:' + item.email"></a>
            </div>
        </div>
        </XyzTransition>
    </section>
</template>

<script>
    export default {
        name: 'ContactGroup',
        data() {
            return {

            }
        },
        mounted() {

        },
        methods: {

        },
    }
</script>