<template>
    <section class="component-imagewithtext-vue grid relative min-h-[400px] bg-section-grey px-0 xl:px-10 z-20">
        <div class="container mx-auto self-center px-8 xl:px-0  py-10 md:py-0  md:my-[190px]">
            <XyzTransition :appear-visible="{ threshold: 0.5, rootMargin: '50px' }" duration="auto">
            <div class="md:w-5/12 md:pr-10"  xyz="fade down duration-10 stagger">
                <div class="text-sm mb-4 uppercase leading-none font-serif tracking-[1px]" v-show="blockData.kicker" v-html="blockData.kicker" ></div>
                <h4 class="text-2xl mb-4 font-serif tracking-[1px] " v-show="blockData.title" v-html="blockData.title" ></h4>
                <div v-show="blockData.text" v-html="blockData.text" class="text-[14px] leading-[22px] "></div>
                <a class="btn btn--transparent-blue w-[202px] mt-6 " v-if="blockData.cta" :href="blockData.cta.url" :target="blockData.cta.target">{{ blockData.cta.title }}</a>
            </div>
            </XyzTransition>
        </div>
        <div class="md:absolute md:top-0 md:left-1/2 md:right-0 md:bottom-0 object-cover object-center md:w-1/2 md:h-full overflow-hidden" >
            <XyzTransition appear-visible duration="auto" xyz="big-5 delay-5 duration-30 ease-ease">
                <img class="w-full"  v-show="blockData.image" :src="blockData.image.url" :alt="blockData.image.alt">
            </XyzTransition>
        </div>
    </section>
</template>

<script>
    export default {
        name: 'ImageWithText',
        data() {
            return {

            }
        },
        mounted() {
        
        },
        methods: {

        },
    }
</script>
