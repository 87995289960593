<template>
    <section class="component-triplebox-vue  my-10 xl:my-20 relative z-20">
        <XyzTransition appear-visible duration="auto">
            <div class="container mx-auto px-8 xl:px-0 py-10" xyz="fade down duration-15 stagger">
                <div class="font-serif tracking-[1px] text-lg xyz-nested" v-show="blockData.title" v-html="blockData.title"></div>
                <hr class=" mt-[47px] mb-[32px] border-hr">
                <div class="grid grid-cols-1 md:grid-cols-3 gap-1 xl:gap-10">
                    <div v-show="blockData.boxes" v-for="( item, index ) in blockData.boxes" :key="index">                   
                        <FlipCard :cardData="item"></FlipCard>
                    </div>    
                </div>
            </div>
        </XyzTransition>
    </section>
</template>

<script>
    import FlipCard from './FlipCard.vue'
    export default {
        name: 'TripleBox',
        components: {
            FlipCard
        },
        data() {
            return {

            }
        },
        mounted() {

        },
        methods: {

        },
    }
</script>