import axios from 'axios';

// doing something with the request
axios.interceptors.request.use(
	(request) => {
		if ( typeof( window.current_language ) !== 'undefined' ) {
			var lang = window.current_language.split('_')[0]
			var params = request.url.split('?')
			request.url = params.length > 1 ? request.url + '&lang=' + lang : request.url + '?lang=' + lang
		}
		return request;
	}
);

// doing something with the response
axios.interceptors.response.use(
	(response) => {
		return response;
	},
	(error) => {
		return Promise.reject(error);
	}
);

export default axios;
