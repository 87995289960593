<template>
    <div class="video-background">
            <iframe width="560" height="315" :src="'https://www.youtube.com/embed/' + video + '?autoplay=1&amp;controls=0&amp;modestbranding=1'" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
</template>


<script>
    export default {
        name: 'CustomSource',
        props: ['video'],
        data() {
            return {

            }
        },
        mounted() {

        },
        methods: {

        },
    }
</script>

<style scoped>

.video-background {
  position: relative;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
}

.video-background iframe {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100vw;
  height: 100vh;
  transform: translate(-50%, -50%);
}

@media (min-aspect-ratio: 16/9) {
  .video-background iframe {
    /* height = 100 * (9 / 16) = 56.25 */
    height: 56.25vw;
  }
}
@media (max-aspect-ratio: 16/9) {
  .video-background iframe {
    /* width = 100 / (9 / 16) = 177.777777 */
    width: 177.78vh;
  }
}

</style>