<template>
    <!-- Destop verison -->
    <section v-if="!isMobile" class="component-slider-vue relative z-20 bg-section-grey pt-20 pb-14">
            <XyzTransition :appear-visible="{ threshold: 0.3, rootMargin: '20px' }" duration="auto">
                <div xyz="ne diraj">
                    <div class="overflow-hidden">

                        <!-- slides -->
                        <div ref="slidesWrapper" xyz="fade left-5 duration-15 stagger">
                            <div ref="slidesHolder" class="flex xyz-nested">
                                <single-slide v-for="item in 5" ref="slides" :data-slide-index="item - 1" :key="item"/>
                            </div>
                        </div>
                        <!-- /slides -->

                        <!-- arrows -->
                        <div class="slider-arrows">
                            <div class="slider-arrows__prev" @click="prevSlide">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M15 19l-7-7 7-7" />
                                </svg>
                            </div>
                            <div class="slider-arrows__next" @click="nextSlide">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M9 5l7 7-7 7" />
                                </svg>
                            </div>
                        </div>
                        <!-- /arrows -->

                        <!-- dots -->
                        <div v-if="slidesData" class="flex dots-holder items-center px-8 justify-center" >
                            <div class="dot" v-for="(item, index) in slidesData" :class="{'active' : index == currentSlideIndex}" :key="'dot-' + index">{{getPaddedNum( index+1 )}}
                                <progress-ring v-show="index == currentSlideIndex"  stroke="2" radius="29" :progress="(100 / slidesData.length) * (currentSlideIndex+1)"></progress-ring>
                            </div>
                        </div>
                        <!-- /dots -->

                    </div>
                </div>
            </XyzTransition>
    </section>


    <!-- Mobile version -->
    <section v-else class="component-slider-vue relative z-20 bg-section-grey pt-3 md:pt-12 pb-14">
        <div class="mx-auto bg-section-grey items-center">
            <splide :options="splideOptions" ref="splideSlider" @splide:moved="splideMoved">

                <splide-slide v-for="( slide, index ) in blockData.slides" :key="index">
                    <div class="flex flex-col h-full">
                        <img v-show="slide.image" :src="slide.image.url" :alt="slide.image.alt">
                        <div class="bg-white pt-8 px-6 pb-10 h-full">
                            <div class="text-lg leading-tight font-serif mb-[1em]" v-show="slide.title" v-html="slide.title"></div>
                            <div class="text-sm" v-show="slide.text" v-html="slide.text"></div>
                        </div>
                    </div>
                </splide-slide>

            </splide>
        </div>

        <!-- dots -->
        <div v-if="blockData.slides" class="flex dots-holder items-center px-8 justify-center">
            <div class="dot" v-for="(item, index) in blockData.slides" :class="{'active' : index == currentSlideIndex}" :key="'dot-' + index">{{getPaddedNum( index+1 )}}
                <progress-ring v-show="index == currentSlideIndex"  stroke="2" radius="29" :progress="(100 / blockData.slides.length) * (currentSlideIndex+1)"></progress-ring>
            </div>
        </div>
        <!-- /dots -->

        <template v-if="blockData.cta_type == 'Link'">
            <img v-show="blockData.image" :src="blockData.image.url" :alt="blockData.alt">
            <div v-show="blockData.kicker" v-html="blockData.kicker" ></div>
            <div v-show="blockData.text" v-html="blockData.text" ></div>
            <a v-if="blockData.cta" :href="blockData.cta.url" :target="blockData.cta.target">{{ blockData.cta.title }}</a>
        </template>
    </section>
</template>

<script>
    import SingleSlide from './SingleSlide.vue'
    import anime from 'animejs/lib/anime.es'
    import { Splide, SplideSlide } from '@splidejs/vue-splide';
	import '@splidejs/splide/dist/css/themes/splide-default.min.css';

    export default {
        name: 'Slider',
        components: {
            'single-slide': SingleSlide,
            Splide,
			SplideSlide,
		},
        data() {
            return {
                currentSlideIndex: 0,
                slidesData: false,
                sliderLeftOffset: 0,
                slideSize: 0,
                running: false,
                animDuration: 1000,
                easingFunction: 'easeOutCubic',
                initialization: true,

                isMobile: false,
                splideOptions: {
                    type: 'loop',
                    rewind: false,
                    focus: 'center',
                    perPage: 1,
                    clones: 0,
                    perMove: 1,
                    arrows: true,
                    trimSpace: false,
                    pagination: false,
                    speed: 1000,
                    breakpoints: {
                        640: {
                            arrows: false,
                            gap: '1rem',
                            padding: {
                                left : '48px',
                                right: '48px',
                            },
                            
                        },
                    }
                }, 
            }
        },
        mounted() {
            let mediaQuery = window.matchMedia('(min-width: 1024px)')
            if ( !mediaQuery.matches ) {
                this.isMobile = true
            } 

            if ( !this.isMobile ) {
                this.slidesData = this.blockData.slides
                this.setSlidesData()
                this.setSlidesSize()
                window.addEventListener( 'resize', this.setSlidesSize )
            }
        },
        methods: {
            getPaddedNum( num ) {
                return ( num < 10 ) ? ( '0' + num ) : num
            },
            getIndexes() {
                let prevIndex = this.currentSlideIndex - 1 < 0 ? this.slidesData.length - 1 : this.currentSlideIndex - 1
                let nextIndex = this.currentSlideIndex + 1 < this.slidesData.length ? this.currentSlideIndex + 1 : 0
                let bufferLeftIndex = prevIndex - 1 < 0 ? this.slidesData.length - 1 : prevIndex - 1
                let bufferRightIndex = nextIndex + 1 > this.slidesData.length - 1 ? 0 : nextIndex + 1
                return {
                    prevIndex:          prevIndex,
                    nextIndex:          nextIndex,
                    bufferLeftIndex:    bufferLeftIndex,
                    bufferRightIndex:   bufferRightIndex
                }
            },
            setSlidesData() {
                let indexes = this.getIndexes()
                this.$refs.slides[0].setSlideData( this.slidesData[ indexes.bufferLeftIndex ] )
                this.$refs.slides[1].setSlideData( this.slidesData[ indexes.prevIndex ] )
                this.$refs.slides[2].setSlideData( this.slidesData[ this.currentSlideIndex ] )
                this.$refs.slides[3].setSlideData( this.slidesData[ indexes.nextIndex ] )
                this.$refs.slides[4].setSlideData( this.slidesData[ indexes.bufferRightIndex ] )
            },
            setSlidesSize() {
                this.sliderLeftOffset = ( window.outerWidth * 0.7 ) * 1.75
                this.$refs.slidesWrapper.style.transform = 'translateX(-' + this.sliderLeftOffset + 'px)'
                this.$refs.slidesWrapper.style.width = ( ( window.outerWidth * 0.7 ) * 5 ) + 'px'
                this.$refs.slides.forEach( ( slide ) => {
                    slide.setSize( window.outerWidth * 0.6, window.outerWidth * 0.1 )
                } )
                if ( this.initialization ) {
                    this.$refs.slides[0].setAlignment('right')
                    this.$refs.slides[1].setAlignment('right')
                    this.$refs.slides[2].openInstant()
                    this.$refs.slides[3].setAlignment('left')
                    this.$refs.slides[4].setAlignment('left')
                    this.initialization = false
                }
                this.slideSize = window.outerWidth * 0.7
            },
            nextSlide() {
                if ( this.running ) {
                    return
                }
                let self = this
                this.running = true
                anime({
                    targets: this.$refs.slidesWrapper,
                    translateX: - this.sliderLeftOffset - this.slideSize,
                    translateZ: 0,
                    easing: this.easingFunction,
                    duration: this.animDuration,
                    complete: function( anim ) {
                        self.$refs.slidesHolder.append( self.$refs.slidesHolder.firstChild )
                        self.$refs.slidesWrapper.style.transform = 'translateX(-' + self.sliderLeftOffset + 'px)'
                        self.updateBufferSlides()
                        self.running = false
                    }
                })

                let currentDot = this.$el.querySelector( '.dot.active' )
                anime({
                    targets: currentDot,
                    scale: [1, 0],
                    opacity: [1, 0],
                    easing: this.easingFunction,
                    duration: this.animDuration / 2,
                    complete: function( anim ) {
                        self.currentSlideIndex = self.currentSlideIndex + 1 == self.slidesData.length ? 0 : self.currentSlideIndex + 1
                        self.$nextTick(function() {
                            let activeDot = self.$el.querySelector( '.dot.active' )
                            anime({
                                targets: [activeDot, currentDot],
                                scale: [0, 1],
                                opacity: [0, 1],
                                easing: self.easingFunction,
                                duration: self.animDuration / 2
                            })
                        })
                    }
                })

                let openElement = this.$refs.slidesHolder.childNodes[3]
                this.$refs.slides[ openElement.dataset.slideIndex ].openLeft()

                let closeElement = this.$refs.slidesHolder.childNodes[2]
                this.$refs.slides[ closeElement.dataset.slideIndex ].closeRight()
            },
            prevSlide() {
                if ( this.running ) {
                    return
                }
                let self = this
                this.running = true
                anime({
                    targets: this.$refs.slidesWrapper,
                    translateX: - this.sliderLeftOffset + this.slideSize,
                    translateZ: 0,
                    easing: this.easingFunction,
                    duration: this.animDuration,
                    complete: function( anim ) {
                        self.$refs.slidesHolder.prepend( self.$refs.slidesHolder.lastChild )
                        self.$refs.slidesWrapper.style.transform = 'translateX(-' + self.sliderLeftOffset + 'px)'
                        self.updateBufferSlides()
                        self.running = false
                    }
                })

                let currentDot = this.$el.querySelector( '.dot.active' )
                anime({
                    targets: currentDot,
                    scale: [1, 0],
                    opacity: [1, 0],
                    easing: this.easingFunction,
                    duration: this.animDuration / 2,
                    complete: function( anim ) {
                        self.currentSlideIndex = self.currentSlideIndex - 1 < 0 ? self.slidesData.length -1 : self.currentSlideIndex - 1
                        self.$nextTick(function() {
                            let activeDot = self.$el.querySelector( '.dot.active' )
                            anime({
                                targets: [activeDot, currentDot],
                                scale: [0, 1],
                                opacity: [0, 1],
                                easing: self.easingFunction,
                                duration: self.animDuration / 2
                            })
                        })
                    }
                })

                let openElement = this.$refs.slidesHolder.childNodes[1]
                this.$refs.slides[ openElement.dataset.slideIndex ].openRight()

                let closeElement = this.$refs.slidesHolder.childNodes[2]
                this.$refs.slides[ closeElement.dataset.slideIndex ].closeLeft()
            },
            updateBufferSlides() {
                let indexes = this.getIndexes()
                let rightBuffer = this.$refs.slidesHolder.lastChild
                let leftBuffer = this.$refs.slidesHolder.firstChild
                this.$refs.slides[ rightBuffer.dataset.slideIndex ].setSlideData( this.slidesData[ indexes.bufferRightIndex ] )
                this.$refs.slides[ rightBuffer.dataset.slideIndex ].setAlignment( 'left' )
                this.$refs.slides[ leftBuffer.dataset.slideIndex ].setSlideData( this.slidesData[ indexes.bufferLeftIndex ] )
                this.$refs.slides[ leftBuffer.dataset.slideIndex ].setAlignment( 'right' )
            },
            splideMoved(splide) {
               this.currentSlideIndex = splide.index
            }
        },
    }
</script>

<style>
    .dot.active svg {
        transform: scale(1.12);
    }
</style>
