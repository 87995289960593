<template>
    <section class="component-imagewithtextsteps-vue relative z-20 py-10  md:py-20" :class="blockData.blockClasses">
        <div class="container mx-auto px-8 xl:px-10">

            <div class="flex flex-col lg:flex-row xl:justify-between mx-auto gap:-10 xl:gap-20 xl:w-10/12">
                <div class="w-full self-center mb-8 xl:mb-0  xl:w-1/2 mx-auto xl:pr-[5%]">
                    <XyzTransition :appear-visible="{ threshold: 0.3, rootMargin: '20px' }" duration="auto" >
                        <div xyz="fade down duration-10 stagger">
                            <h3 class="font-serif tracking-[1px] text-sm mb-4 xyz-nested" v-show="blockData.kicker">{{ blockData.kicker }}</h3>
                            <h2 class="font-serif tracking-[1px] text-[24px] mb-4 leading-tight xyz-nested" v-show="blockData.title">{{ blockData.title }}</h2>
                            <p class="font-sans text-[14px] leading-[22px] xyz-nested" v-show="blockData.text" v-html="blockData.text"></p>
                        </div>
                    </XyzTransition>
                </div>
                <div class="w-full xl:w-1/2 xl:pl-[2.5%] mb-4 xl:mb-0 overflow-hidden">
                    <XyzTransition :appear-visible="{ threshold: 0.5, rootMargin: '50px' }" duration="auto" >
                        <div xyz="big-5 delay-5 duration-30">
                            <img class="w-full"  v-show="blockData.image" :src="blockData.image.url" :alt="blockData.alt">
                        </div>
                    </XyzTransition>
                </div>
            </div>

            <!-- Accordion -->
            <div class="mt-10 xl:mt-20 lg:w-10/12 mx-auto grid grid-flow-row grid-cols-1 xl:grid-cols-2 xl:gap-20 ">
                <template v-if="!isMobile">
                    <ul class="tab__header_wrap">
                        <XyzTransition :appear-visible="{ threshold: 0.3, rootMargin: '20px' }" duration="auto" >
                            <div xyz="fade down duration-10 stagger">
                                <li class="tab__header self-start xyz-nested" v-for="(step, index) in stepsOne" :key="index" >
                                    <div class="tab__link py-7  bg-blue-dark hover:bg-blue-darker no-underline flex justify-between cursor-pointer" @click="switchTab(step)">
                                        <div class="flex items-center">
                                            <div class="font-serif text-[32px] w-12 text-center flex-shrink-0" v-html="index+1"></div>
                                            <div class="font-serif text-[18px] tracking-[1px]">{{ step.title }}</div>
                                        </div>
                                        <span class="down-Arrow bg-gray-200 rounded-full w-[32px] h-[32px] grid place-content-center flex-shrink-0">
                                            <svg v-if="activeStep != step" xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M19 9l-7 7-7-7" />
                                            </svg>
                                            <svg v-if="activeStep == step" xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M5 15l7-7 7 7" />
                                            </svg>
                                        </span>
                                    </div>
                                    <!-- <transition name="content"> -->
                                    <div class="tab__content pt-2 pb-10 px-12" v-show="activeStep == step" v-html="activeStep.text"></div>   
                                    <!-- </transition>                      -->
                                </li>
                            </div>
                        </XyzTransition>
                    </ul>
                    <!-- ./Accordion -->
                    <!-- Accordion -->
                    <ul class="tab__header_wrap">
                        <XyzTransition :appear-visible="{ threshold: 0.3, rootMargin: '20px' }" duration="auto" >
                            <div xyz="fade down duration-10 stagger">
                                <li class="tab__header self-start" v-for="(step, index) in stepsTwo" :key="index">
                                    <div class="tab__link py-7  bg-blue-dark hover:bg-blue-darker no-underline flex justify-between cursor-pointer" @click="switchTab(step)">
                                        <div class="flex items-center">
                                            <div class="font-serif text-[32px] w-12 text-center flex-shrink-0" v-html="index+1+firstColNum"></div>
                                            <div class="font-serif text-[18px] tracking-[1px]">{{ step.title }}</div>
                                        </div>
                                        <span class="down-Arrow bg-gray-200 rounded-full w-[32px] h-[32px] grid place-content-center flex-shrink-0">
                                            <svg v-if="activeStep != step" xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M19 9l-7 7-7-7" />
                                            </svg>
                                            <svg v-if="activeStep == step" xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M5 15l7-7 7 7" />
                                            </svg>
                                        </span>
                                    </div>
                                    <!-- <transition name="content"> -->
                                    <div class="tab__content pt-2 pb-10 px-12" v-show="activeStep == step" v-html="activeStep.text"></div>   
                                    <!-- </transition>                      -->
                                </li>
                            </div>
                        </XyzTransition>
                    </ul>
                    <!-- ./Accordion -->
                </template>
                <template v-else>
                    <ul class="tab__header_wrap">
                        <li class="tab__header self-start" v-for="(step, index) in blockData.steps" :key="index">
                            <div class="tab__link py-4  bg-blue-dark hover:bg-blue-darker no-underline flex justify-between" @click="switchTab(step)">
                                <div class="flex items-center">
                                    <div class="font-serif text-[32px] w-12 text-center flex-shrink-0" v-html="index+1"></div>
                                    <div class="font-serif text-[18px]">{{ step.title }}</div>
                                </div>
                                <span class="down-Arrow bg-gray-200 rounded-full w-[32px] h-[32px] grid place-content-center flex-shrink-0">
                                    <svg v-if="activeStep != step" xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M19 9l-7 7-7-7" />
                                    </svg>
                                    <svg v-if="activeStep == step" xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M5 15l7-7 7 7" />
                                    </svg>
                                </span>
                            </div>
                            <!-- <transition name="content"> -->
                            <div class="tab__content pt-2 pb-10 px-12" v-show="activeStep == step" v-html="activeStep.text"></div>   
                            <!-- </transition>                      -->
                        </li>
                    </ul>
                </template>

            </div>

        </div>
    </section>
</template>

<script>
    export default {
        name: 'ImageWithTextSteps',
        data() {
            return {
                activeStep: false,
                firstColNum: false
            }
        },
        mounted() {
            this.firstColNum = Math.ceil(this.blockData.steps.length / 2)
        },
        computed: {
            stepsOne: function () {          
                return this.blockData.steps.slice(0, this.firstColNum)
            },
            stepsTwo: function () {
                return this.blockData.steps.slice(this.firstColNum, this.blockData.steps.length)
            },
        },
        methods: {
             switchTab(step) {
                if ( this.activeStep == step ) {
                    this.activeStep = false
                } else {
                    this.activeStep = step
                }
            },
        },
    }
</script>

<style scoped>

/* .content-enter-active,
.content-leave-active {
    transition: all 0.5s ease-in-out;
}

.content-enter,
.content-leave-to {
  visibility: hidden;
  height: 0;
  margin: 0;
  padding: 0;
  opacity: 0;
}

.tab__content {
    overflow: hidden;
} */

/* .tab__header_wrap {
    columns: 2;
    column-gap: 5%;
}
.tab__header_wrap li {
    -webkit-column-break-inside: avoid;
          page-break-inside: avoid;
               break-inside: avoid;
} */
</style>